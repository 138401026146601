<div class="tc-deal-parties">
  <div></div>
  <div>
    <mat-checkbox [formControl]="moForm.controls.proformaNeeded">Proforma needed</mat-checkbox>
  </div>
  <div class="tc-parties-left" gdColumns="repeat(4, minmax(0px, 1fr))" gdGap="15px">
    <mat-form-field gdColumn="span 2">
      <mat-label>Supplier</mat-label>
      <input readonly matInput [value]="supplierName$ | async">
    </mat-form-field>
    <mat-form-field gdColumn="span 1">
      <mat-label>Supplier ref. number</mat-label>
      <input matInput [formControl]="moForm.controls.supplierRef" autocomplete="off">
    </mat-form-field>
    <tc-select-search
      gdColumn="span 1"
      placeholder="Country Of Origin"
      [ctrl]="moForm.controls.originCountryCode"
      [items]="countries$ | async"
      bindValue="code"
      bindLabel="name"
    ></tc-select-search>

    <tc-select-search placeholder="Pickup"
      gdColumn="span 2"
      [ctrl]="moForm.controls.originLocationId"
      [items]="locations$ | async"
      bindValue="location_id"
      bindLabel="name"
      (change)="onOriginLocationChange($event)"
    ></tc-select-search>
    @if (enableRouteService && enableFreightRatePaths) {
      <mat-radio-group
        [formControl]="moForm.controls.originIsPort"
        gdColumn="span 1"
        gdRow="span 2"
        (change)="onOriginTypeChange($event)"
        >
        <mat-label>Pickup Type</mat-label>
        <mat-radio-button [disabled]="isAutomatedOffer" [value]="false">Location</mat-radio-button>
        <mat-radio-button [disabled]="isAutomatedOffer" [value]="true"> Port</mat-radio-button>
      </mat-radio-group>
    }
    <tc-select-search
      placeholder="Contact"
      [multiple]="true"
      selectedTextFormat=""
      [ctrl]="moForm.controls.supplierUserIds"
      [items]="supplierUsers$ | async"
      bindValue="user_id"
      bindLabel="fullname"
      bindHint="primaryemail"
    ></tc-select-search>
    @if (enableRouteService && enableFreightRatePaths) {
      <tc-select-search #originPort
        placeholder="Pickup Port"
        gdColumn="span 2"
        [ctrl]="moForm.controls.originPortId"
        [items]="unlocodes$ | async"
        bindValue="unlocode"
        bindLabel="displayName"
        bindHint="unlocode"
      ></tc-select-search>
    }
    <tc-select-search placeholder="Buying trader"
      [ctrl]="moForm.controls.supplierTraderId"
      [items]="supplierTraders$ | async"
      bindValue="id"
      bindLabel="name"
    ></tc-select-search>

    <tc-epoch-range-field placeholder="Shipment date range"
      gdColumn="span 2"
      [useUtc]="true"
      [useTemplates]="true"
      [min]="TODAY"
      [max]="moForm.controls.deliveryDatesFrom.value"
      [formGroup]="moForm"
      [readonly]="isAutomatedOffer"
      startCtrlName="shipmentDatesFrom"
      endCtrlName="shipmentDatesTo"
      tbdCtrlName="shipmentDatesTbd"
      comparisonStartCtrlName="deliveryDatesFrom"
      comparisonEndCtrlName="deliveryDatesTo"
    ></tc-epoch-range-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Payment Terms</mat-label>
      <input readonly matInput [value]="supplierPaymentTermsStr$ | async">
    </mat-form-field>
  </div>

  <div class="tc-parties-right" gdColumns="repeat(4, minmax(0, 1fr))" gdGap="15px">
    <mat-form-field gdColumn="span 2">
      <mat-label>Buyer</mat-label>
      <input readonly matInput [value]="buyerName$ | async">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Buyer ref. number</mat-label>
      <input matInput [formControl]="moForm.controls.buyerRef" autocomplete="off">
    </mat-form-field>
    <tc-select-search placeholder="Docs Country"
      [ctrl]="moForm.controls.docsCountryCode"
      [items]="countries$ | async" bindValue="code" bindLabel="name"
      (change)="onDocsCountryChange()">
    </tc-select-search>

    <tc-select-search placeholder="Destination"
      [ctrl]="moForm.controls.destLocationId"
      [items]="locations$ | async"
      bindValue="location_id"
      bindLabel="name"
      (change)="onDestLocationChange($event)"
    ></tc-select-search>
    @if (enableRouteService && enableFreightRatePaths) {
      <mat-radio-group
        [formControl]="moForm.controls.destIsPort"
        gdColumn="span 1"
        gdRow="span 2"
        (change)="onDestTypeChange($event)"
        >
        <mat-label>Destination Type</mat-label>
        <mat-radio-button [value]="false">Location</mat-radio-button>
        <mat-radio-button [value]="true"> Port</mat-radio-button>
      </mat-radio-group>
    }
    <tc-select-search
      placeholder="Logistics coordinator"
      [ctrl]="moForm.controls.logisticsUserId"
      [items]="coordinators$ | async"
      bindValue="id"
      bindLabel="name"
      bindHint="hint"
    ></tc-select-search>
    <tc-select-search
      placeholder="Contact"
      [multiple]="true"
      selectedTextFormat=""
      [ctrl]="moForm.controls.buyerUserIds"
      [items]="buyerUsers$ | async"
      bindValue="user_id"
      bindLabel="fullname"
      bindHint="primaryemail"
    ></tc-select-search>
    @if (enableRouteService && enableFreightRatePaths) {
      <tc-select-search #destPort
        placeholder="Destination Port"
        [ctrl]="moForm.controls.destPortId"
        [items]="unlocodes$ | async"
        bindValue="unlocode"
        bindLabel="displayName"
        bindHint="unlocode"
      ></tc-select-search>
    }
    <tc-select-search placeholder="Selling trader"
      [ctrl]="moForm.controls.buyerTraderId"
      [items]="buyerTraders$ | async" bindValue="id" bindLabel="name"
    ></tc-select-search>
    <tc-epoch-range-field placeholder="Delivery date range" gdColumn="span 2"
      [useUtc]="true"
      [useTemplates]="true"
      [min]="moForm.controls.shipmentDatesFrom.value || TODAY"
      [formGroup]="moForm"
      startCtrlName="deliveryDatesFrom"
      endCtrlName="deliveryDatesTo"
      tbdCtrlName="deliveryDatesTbd"
      comparisonStartCtrlName="shipmentDatesFrom"
      comparisonEndCtrlName="shipmentDatesTo"
    ></tc-epoch-range-field>
    <mat-form-field gdColumn="span 2">
      <mat-label>Payment Terms</mat-label>
      <input readonly matInput [value]="buyerPaymentTermsStr$ | async">
    </mat-form-field>
  </div>
</div>

<tc-credit-balance [creditPool]="buyerCreditPool$ | async" [balance]="balance$ | async"></tc-credit-balance>

<div class="tc-product-info" gdColumns="minmax(0, 3fr) minmax(0, 1fr)" gdGap="15px">
  <tc-product-field gdColumns="minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)" gdGap="15px"
    [ctrl]="moForm.controls.productId"
    [protein]="true" (change)="onProductChange()"
  ></tc-product-field>
  <tc-select-search placeholder="Item specification"
    [ctrl]="moForm.controls.itemTypeId"
    [items]="itemTypes$ | async" bindLabel="name" bindValue="item_type_id"
  ></tc-select-search>
</div>

<div class="tc-product-params" gdColumns="repeat(2, minmax(0, 1fr))" gdGap="50px">
  <div class="tc-parties-left">
    <h6>Supplier</h6>
    <div gdColumns="minmax(0, 5fr) minmax(0, 2fr) minmax(0, 5fr)" gdGap="15px" style="padding-bottom: 15px">
      <tc-address-field placeholder="Establishment #" [multiple]="true"
        [ctrl]="moForm.controls.establishments"
        [pickerOptions]="establishmentAddressOptions$ | async">
        <div *tcAddressFieldValue="let address = address">
          <div>{{ address.establishment}}@if (address.establishment) {
            <span> – </span>
          }{{ address.name || 'No Name'}}</div>
          <div class="text-gray">{{ address | address:['street1', 'city']}}</div>
        </div>
      </tc-address-field>
      <tc-select-search placeholder="Supplier Incoterm"
        [ctrl]="moForm.controls.supplierIncotermId"
        [items]="pricingTerms$ | async" bindValue="pricing_terms_id" bindLabel="term"
      ></tc-select-search>
      <tc-select-search placeholder="Supplier Incoterm Location"
        [ctrl]="moForm.controls.supplierIncotermLocationId"
        [items]="locations$ | async" bindValue="location_id" bindLabel="name"
      ></tc-select-search>
    </div>
    <div gdColumns="repeat(3, minmax(0, 1fr))" gdGap="15px">
      <mat-form-field>
        <mat-label>Weight</mat-label>
        <input matInput type="number" min="0.01" step="0.01" autocomplete="off"
          [formControl]="moForm.controls.supplierEstWeight"
          (change)="onSupplierWeightChange()"
          [matAutocomplete]="supplierEstWeight">
        <mat-autocomplete autoActiveFirstOption #supplierEstWeight="matAutocomplete" (optionSelected)="onSupplierWeightChange()" (optionSelected)="onSupplierWeightChange()">
          @for (option of weights; track option) {
            <mat-option [value]="option">{{option}}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <tc-select-search placeholder="Unit"
        [ctrl]="moForm.controls.supplierMeasureId"
        (change)="onSupplierMeasureChange($event)"
        [items]="measures$ | async" bindValue="measure_id" bindLabel="name"
      ></tc-select-search>
      <mat-form-field>
        <mat-label>Ind. weight</mat-label>
        <input readonly matInput [value]="supplierIndWeight$ | async">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Price</mat-label>
        <input matInput type="number" step="0.0001" autocomplete="off"
          [formControl]="moForm.controls.supplierEstPrice"
          (change)="onPriceChange()">
      </mat-form-field>
      <tc-select-search placeholder="Currency"
        [ctrl]="moForm.controls.supplierCurrencyCode"
        (change)="onCurrencyChange()"
        [items]="currencies$ | async" bindValue="code" bindLabel="code"
      ></tc-select-search>
      <mat-form-field>
        <mat-label>Ind. price</mat-label>
        <input readonly matInput [value]="supplierIndPrice$ | async">
      </mat-form-field>
    </div>
  </div>

  <div class="tc-parties-right tc-parties-right-reduced">
    <h6>Buyer</h6>
    <div gdColumns="minmax(0, 5fr) minmax(0, 2fr) minmax(0, 5fr)" gdGap="15px" style="padding-bottom: 15px">
      <tc-address-field placeholder="Invoice Address"
        [ctrl]="moForm.controls.invoiceAddress"
        [pickerOptions]="invoiceAddressOptions$ | async"
      ></tc-address-field>
      <tc-select-search placeholder="Buyer Incoterm"
        [ctrl]="moForm.controls.buyerIncotermId"
        [items]="pricingTerms$ | async" bindValue="pricing_terms_id" bindLabel="term"
      ></tc-select-search>
      <tc-select-search placeholder="Buyer Incoterm Location"
        [ctrl]="moForm.controls.buyerIncotermLocationId"
        [items]="locations$ | async" bindValue="location_id" bindLabel="name"
      ></tc-select-search>
    </div>
    <div gdColumns="repeat(3, minmax(0, 1fr))" gdGap="15px">
      <mat-form-field>
        <mat-label>Weight</mat-label>
        <input matInput type="number" min="0.01" step="0.01" autocomplete="off"
          [formControl]="moForm.controls.buyerEstWeight"
          (change)="onBuyerWeightChange()"
          [matAutocomplete]="buyerEstWeight">
        <mat-autocomplete autoActiveFirstOption #buyerEstWeight="matAutocomplete" (optionSelected)="onBuyerWeightChange()">
          @for (option of weights; track option) {
            <mat-option [value]="option">{{option}}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <tc-select-search placeholder="Unit"
        [ctrl]="moForm.controls.buyerMeasureId"
        (change)="onBuyerMeasureChange($event)"
        [items]="measures$ | async" bindValue="measure_id" bindLabel="name"
      ></tc-select-search>
      <mat-form-field>
        <mat-label>Ind. weight</mat-label>
        <input readonly matInput [value]="buyerIndWeight$ | async">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Price</mat-label>
        <input matInput type="number" step="0.0001" autocomplete="off"
          [formControl]="moForm.controls.buyerEstPrice"
          (change)="onPriceChange()">
      </mat-form-field>
      <tc-select-search placeholder="Currency"
        [ctrl]="moForm.controls.buyerCurrencyCode"
        (change)="onCurrencyChange()"
        [items]="currencies$ | async" bindValue="code" bindLabel="code"
      ></tc-select-search>
      <mat-form-field>
        <mat-label>Ind. price</mat-label>
        <input readonly matInput [value]="buyerIndPrice$ | async">
      </mat-form-field>
    </div>
  </div>
</div>

<div class="tc-products-total">
  <div gdColumns="minmax(0, 2fr) minmax(0, 5fr) minmax(0, 5fr)" gdGap="15px" style="padding-bottom: 15px">
    <mat-form-field>
      <mat-label>Total estimated weight</mat-label>
      <input readonly matInput [value]="moForm.controls.supplierEstWeight.value">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Total estimated product cost</mat-label>
      <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr))">
        <input readonly matInput value="{{ moForm.controls.supplierCurrencyCode.value }} {{ buyTotalPrice$ | async | currency:moForm.controls.supplierCurrencyCode.value:'symbol-narrow':'1.0' }}">
        <span>CAD {{ buyTotalPriceCad$ | async | currency:'CAD':'symbol-narrow':'1.0' }}</span>
      </div>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Total estimated product revenue</mat-label>
      <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr))">
        <input readonly matInput value="{{ moForm.controls.buyerCurrencyCode.value }} {{ sellTotalPrice$ | async | currency:moForm.controls.buyerCurrencyCode.value:'symbol-narrow':'1.0' }}">
        <span>CAD {{ sellTotalPriceCad$ | async | currency:'CAD':'symbol-narrow':'1.0' }}</span>
      </div>
    </mat-form-field>
  </div>
  <div gdColumns="minmax(0, 1fr) minmax(0, 1fr)" gdGap="15px">
    <div style="text-align: right">
      <button mat-button type="button" (click)="addSupplierSpecialInstructions()">
        <i class="fa fa-20 fa-file-plus" aria-hidden="true"></i>
        <span>{{ (hasSupplierInstructions$ | async) ? 'EDIT SUPPLIER SPECIAL INSTRUCTIONS' : 'ADD SUPPLIER SPECIAL INSTRUCTIONS' }}</span>
      </button>
      @if (hasSupplierInstructions$ | async; as supplierInstructions) {
        <p style="padding-right: 10px">{{supplierInstructions.body}}</p>
      }
    </div>
    <div>
      <button mat-button type="button" (click)="addBuyerSpecialInstructions()">
        <i class="fa fa-20 fa-file-plus" aria-hidden="true"></i>
        <span>{{ (hasBuyerInstructions$ | async) ? 'EDIT BUYER SPECIAL INSTRUCTIONS' : 'ADD BUYER SPECIAL INSTRUCTIONS' }}</span>
      </button>
      @if (hasBuyerInstructions$ | async; as buyerInstructions) {
        <p style="padding-left: 10px">{{buyerInstructions.body}}</p>
      }
    </div>
  </div>
</div>

<tc-costs-list [types]="['secondary', 'tertiary']"
  [displayColumns]="['service', 'provider', 'createdBy', 'estAmt', 'fxRate', 'estAmtCAD', 'ellipsisMenu']"
  [units]="moForm.controls.supplierMeasureId.value"
  [matchedOffer]="matchedOffer"
  [costsForm]="costsForm"
  [fxRates]="fxRates"
  fxRatesRange="spot"
  (updateCost)="updateCost.next($event)"
  (removeCost)="removeCost.next($event)"
></tc-costs-list>

<div class="tc-offer-footer">
  <div class="tc-footer-dates">
    <mat-form-field>
      <mat-label>Finance term</mat-label>
      <input readonly matInput [value]="0">
      <!-- <input readonly matInput [value]="financeTerm | number:'1.0'"> -->
    </mat-form-field>
    <tc-epoch-field placeholder="Ant. liability date (Supplier due date)" [ctrl]="moForm.controls.supplierLiabilityDate"></tc-epoch-field>
    <mat-form-field>
      <mat-label>Average Days to Due Date</mat-label>
      <input readonly matInput [value]="supplierAvgDaysToDue$ | async">
    </mat-form-field>
    <tc-epoch-field placeholder="Ant. term date" [ctrl]="moForm.controls.buyerTermDate"></tc-epoch-field>
    <tc-epoch-field placeholder="Ant. collection date" [ctrl]="moForm.controls.collectionDate"></tc-epoch-field>
    <mat-form-field>
      <mat-label>Average Days to Due Date</mat-label>
      <input readonly matInput [value]="buyerAvgDaysToDue$ | async">
    </mat-form-field>
  </div>

  <div class="tc-footer-terms-headers">
    <span>Payment terms</span>
    <span>FX Rates</span>
    <span>Payment terms</span>
    <span>Raw cost</span>
    <span>Target cost (6% margin)</span>
  </div>
  <div class="tc-footer-terms">
    <span>{{ supplierPaymentTermsStr$ | async }}</span>
    <span matTooltip="Using spot rates from {{'\n'}} {{ matchedOffer.fx_rate_timestamp | epoch:'L LT' }}"
      matTooltipPosition="above" matTooltipClass="pre-tooltip" matTooltipShowDelay="3000">
      [{{ moForm.controls.supplierCurrencyCode.value }}/CAD] {{ fxRates.rates[moForm.controls.supplierCurrencyCode.value]?.spot.ask }} ASK
    </span>
    <span matTooltip="Using spot rates from {{'\n'}} {{ matchedOffer.fx_rate_timestamp | epoch:'L LT' }}"
      matTooltipPosition="above" matTooltipClass="pre-tooltip" matTooltipShowDelay="3000">
    [{{ moForm.controls.buyerCurrencyCode.value }}/CAD] {{ fxRates.rates[moForm.controls.buyerCurrencyCode.value]?.spot.bid }} BID</span>
    <span>{{ buyerPaymentTermsStr$ | async }}</span>
    <span *ngrxLet="rawCost$ as rawCost">{{ rawCost ? (rawCost | currency:moForm.controls.buyerCurrencyCode.value | measure:moForm.controls.supplierMeasureId.value) : '—'}}</span>
    <span *ngrxLet="targetCost$ as targetCost">{{ targetCost ? (targetCost | currency:moForm.controls.buyerCurrencyCode.value | measure:moForm.controls.supplierMeasureId.value) : '—'}}</span>
  </div>

  <div class="tc-footer-revenue">
    <div>
      <div>Revenue (CAD)</div>
      <em *ngrxLet="revenueCad$ as revenueCad">{{ revenueCad ? (revenueCad | currency:'CAD':'symbol-narrow') : '—'}}</em>
    </div>
    <div>
      <div>Margin (CAD)</div>
      <em *ngrxLet="marginCad$ as marginCad">{{ marginCad ? (marginCad | currency:'CAD':'symbol-narrow') : '—'}}</em>
    </div>
    <div>
      <div>Margin (%)</div>
      <em>{{ margin$ | async }}</em>
    </div>
  </div>
</div>
