<div class="brand">
  <tc-logo></tc-logo>
  <div class="logo-version" title="v{{version.version}} {{version.hash}} {{env}}" (mousemove)="getReplayId()">
    <span>v{{version.version}}</span>
    @if (sentryReplay$ | async; as sentryReplay) {
      <a class="sentry-replay fa fa-brands fa-youtube" [href]="sentryReplay" target="blank"></a>
    }
  </div>
</div>

<section class="tc-nav-icons" fxFlex fxHide fxShow.gt-sm>
  @if (canAccess('app.trading')) {
    <a class="tc-nav-item tc-nav-icon" [matMenuTriggerFor]="tradingMenu" routerLinkActive="active">
      <i class="fas fa-globe" aria-hidden="true"></i>
      Trading
      @if (newMatchedOffers) {
        <span class="fas fa-gift info-accent"></span>
      }
    </a>
  }
  @if (canAccess('app.logistics')) {
    <a class="tc-nav-item tc-nav-icon" [matMenuTriggerFor]="logisticsMenu" routerLinkActive="active">
      <i class="fas fa-truck" aria-hidden="true"></i>
      Logistics
    </a>
  }
  @if (canAccess('app.financial')) {
    <a class="tc-nav-item tc-nav-icon" [matMenuTriggerFor]="financialMenu" routerLinkActive="active">
      <i class="fas fa-money-bill-1" aria-hidden="true"></i>
      Financial
    </a>
  }
  @if (canAccess('app.reports')) {
    <a class="tc-nav-item tc-nav-icon" [matMenuTriggerFor]="chartMenu" routerLinkActive="active">
      <i class="fas fa-chart-bar" aria-hidden="true"></i>
      Reports
    </a>
  }
  @if (canAccess('app.management')) {
    <a class="tc-nav-item tc-nav-icon" [matMenuTriggerFor]="managementMenu" routerLinkActive="active">
      <i class="fas fa-briefcase" aria-hidden="true"></i>
      Management
    </a>
  }
</section>

<section class="tc-nav-items" fxFlex fxShow fxHide.gt-sm>
  <a class="tc-nav-item tc-nav-icon active" [matMenuTriggerFor]="hamburgerMenu" aria-label="Toggle navigation">
    <i class="fas fa-bars" aria-hidden="true"></i>
  </a>
</section>

<section class="tc-nav-items">
  <tc-feeds-bell></tc-feeds-bell>
  <a class="tc-nav-item">
    Help
  </a>
  @if (canAccess('app.setting')) {
    <a class="tc-nav-item" [matMenuTriggerFor]="settingsMenu">
      Settings <span class="caret"></span>
    </a>
  }
  <a class="tc-nav-item" [matMenuTriggerFor]="authMenu">
    {{username || 'Anonymous'}}<span class="caret"></span>
  </a>
</section>

<mat-menu #settingsMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item
      routerLink="/setting/consignees" routerLinkActive="active"
      matTooltip="View and update the list of consignees." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Settings Consignees Page')">
      Consignees
    </a>
    <a mat-menu-item
      routerLink="/setting/companies" routerLinkActive="active"
      matTooltip="View and update the list of companies, departments, and business types." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Companies Page')">
      Companies
    </a>
    <a mat-menu-item
      routerLink="/setting/geographic" routerLinkActive="active"
      matTooltip="View and update the list of countries." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Geographic Page')">
      Geographic
    </a>
    <a mat-menu-item
      routerLink="/setting/locations" routerLinkActive="active"
      matTooltip="View and update the list of locations available for shipping." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Locations Page')">
      Locations
    </a>
    <a mat-menu-item
      routerLink="/setting/unlocodes" routerLinkActive="active"
      matTooltip="View the list of unlocodes available for shipping." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Unlocodes Page')">
      Unlocodes
    </a>
    <a mat-menu-item
      routerLink="/setting/tracking-providers" routerLinkActive="active"
      matTooltip="View and update the list of tracking providers." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Tracking Providers Page')">
      Tracking Providers
    </a>
    <a mat-menu-item
      routerLink="/setting/transit-times" routerLinkActive="active"
      matTooltip="View and update the list of known transit times." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Transit Times Page')">
      Transit Times
    </a>
    <mat-divider></mat-divider>
    <a mat-menu-item
      routerLink="/setting/products-services" routerLinkActive="active"
      matTooltip="View and update the list of products, product types, product categories, and product specifications." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Products & Services Page')">
      Products & Services
    </a>
    <a mat-menu-item
      routerLink="/setting/product-specs" routerLinkActive="active"
      matTooltip="View and update the list of product packaging, measurements, weights, and types." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Product Specifications Page')">
      Product Specifications
    </a>
    <mat-divider></mat-divider>
    @if (canAccess('app.setting.custom-costs.default')) {
      <a mat-menu-item
        routerLink="/setting/custom-costs" routerLinkActive="active"
        matTooltip="View and update the secondary costs automatically assocaited with a deal." matTooltipPosition="left" matTooltipClass="nav-tooltip"
        (click)="track('Visited Custom Costs Page')">
        Custom Costs
      </a>
    }
    @if (canAccess('app.setting.payments.default')) {
      <a mat-menu-item
        routerLink="/setting/payments" routerLinkActive="active"
        matTooltip="View and update the list of payment specific date like currency, pricing terms, and payment references." matTooltipPosition="left" matTooltipClass="nav-tooltip"
        (click)="track('Visited Payments Page')">
        Payments
      </a>
    }
    <mat-divider></mat-divider>
    @if (canAccess('app.setting.document-library.default')) {
      <a mat-menu-item
        routerLink="/setting/document-library" routerLinkActive="active"
        matTooltip="View and update the document library." matTooltipPosition="left" matTooltipClass="nav-tooltip"
        (click)="track('Visited Document Library Page')">
        Document Library
      </a>
    }
    @if (canAccess('app.setting.triggers.default')) {
      <a mat-menu-item
        routerLink="/setting/triggers" routerLinkActive="active"
        matTooltip="View and update the application trigger settings." matTooltipPosition="left" matTooltipClass="nav-tooltip"
        (click)="track('Visited Notification Triggers Page')">
        Notification Triggers
      </a>
    }
    @if (canAccess('app.setting.templates.default')) {
      <a mat-menu-item
        routerLink="/setting/templates" routerLinkActive="active"
        matTooltip="View and update the application template settings." matTooltipPosition="left" matTooltipClass="nav-tooltip"
        (click)="track('Visited Message Templates Page')">
        Message Templates
      </a>
    }
    @if (canAccess('app.setting.custom-tags.default')) {
      <a mat-menu-item
        routerLink="/setting/custom-tags" routerLinkActive="active"
        matTooltip="View and update the application custom tag settings." matTooltipPosition="left" matTooltipClass="nav-tooltip"
        (click)="track('Visited Custom Tags Page')">
        Custom Tags
      </a>
    }
    <a mat-menu-item
      routerLink="/setting/status-notes" routerLinkActive="active"
      matTooltip="View and update the application status note settings." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Logistics Notes Page')">
      Logistics Notes
    </a>
  </ng-template>
</mat-menu>

<mat-menu #authMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <button mat-menu-item
      matTooltip="Change your password." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="changePassword()">
      Change password
    </button>
    <button mat-menu-item
      matTooltip="Log out of the application." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="logout()">
      Logout
    </button>
  </ng-template>
</mat-menu>

<mat-menu #tradingMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item
      [routerLink]="routeUrl('app.trading.estimates')" routerLinkActive="active"
      matTooltip="View your list of previously created estimates." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Estimates Page')">
      Estimates
    </a>
    <a mat-menu-item
      [routerLink]="routeUrl('app.trading.supplier-offers')" routerLinkActive="active"
      matTooltip="View your list of all supplier offers." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Supplier Offers Page')">
      Supplier Offers
    </a>
    <a mat-menu-item
      [routerLink]="routeUrl('app.trading.matched-offers')" routerLinkActive="active"
      matTooltip="View your list of all matched offers." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Matched Offers Page')">
      Matched Offers
      @if (newMatchedOffers) {
        <span class="info-accent">{{newMatchedOffers}}</span>
      }
    </a>
    @if (enableMultipleBids) {
      <a mat-menu-item
        routerLink="/trading/future-bids" routerLinkActive="active"
        matTooltip="View your list of all  bids." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited My Bids Page')">
         My Bids
      </a>
    }
    <mat-divider></mat-divider>
    <a mat-menu-item
      routerLink="/trading/deals" routerLinkActive="active"
      matTooltip="View your list of previously created deals." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Deals List Page')">
      Deals List
    </a>
    <a mat-menu-item
      routerLink="/trading/deals/new" routerLinkActive="active"
      matTooltip="Create a new deal." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited New Deal Page')">
      New Deal
    </a>
    <a mat-menu-item
      [routerLink]="routeUrl('app.trading.fx-rates')" routerLinkActive="active"
      matTooltip="View past and present foreign exchange rates." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited FX Rates Page')">
      FX Rates
    </a>
    <a mat-menu-item
      [routerLink]="routeUrl('app.trading.freight_rates')" routerLinkActive="active"
      matTooltip="View active freight rates available for shipping." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Freight Rates Page')">
      Freight Rates
    </a>
    <mat-divider></mat-divider>
    <a mat-menu-item
      routerLink="/trading/shipping-log" routerLinkActive="active"
      matTooltip="View your shipping log." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Shipping Log Page')">
      Shipping Log
    </a>
    <a mat-menu-item
      routerLink="/trading/client-shipping-log" routerLinkActive="active"
      matTooltip="Edit your client's shipping log view." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Client Shipping Log Page')">
      Client Shipping Log
    </a>
    <mat-divider></mat-divider>
    <a mat-menu-item
      routerLink="/trading/vendor-invoices" routerLinkActive="active"
      matTooltip="View your list of vendor invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Vendor Invoices Page')">
      Vendor Invoices
    </a>
    <a mat-menu-item
      routerLink="/trading/buyer-invoices" routerLinkActive="active"
      matTooltip="View your list of buyer invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Buyer Invoices Page')">
      Buyer Invoices
    </a>
    @if (WA_11596_CREDIT_NOTES_UPGRADE) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.trading.credit-notes-upgraded')" routerLinkActive="active"
        matTooltip="View your list of credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Credit Notes Page')">
        Credit Notes
      </a>
    } @else {
      <a mat-menu-item
        [routerLink]="routeUrl('app.trading.credit-notes')" routerLinkActive="active"
        matTooltip="View your list of credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Credit Notes Page')">
        Credit Notes
      </a>
    }
    @if (WA_11595_VENDOR_CREDITS_UPGRADE) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.trading.vendor-credits-upgraded')" routerLinkActive="active"
        matTooltip="View your list of vendor credits." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Vendor Credits Page')">
        Vendor Credits
      </a>
    } @else {
      <a mat-menu-item
        [routerLink]="routeUrl('app.trading.vendor-credits')" routerLinkActive="active"
        matTooltip="View your list of vendor credits." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Vendor Credits Page')">
        Vendor Credits
      </a>
    }
    <a mat-menu-item
      [routerLink]="routeUrl('app.trading.ar-report')" routerLinkActive="active"
      matTooltip="This report displays information for overdue buyer invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited AR Report Page')">
      AR Report
    </a>
    <mat-divider></mat-divider>
    <a mat-menu-item
      routerLink="/setting/companies" routerLinkActive="active"
      matTooltip="View and update the list of companies, departments, and business types." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Company Settings Page')">
      Company Settings
    </a>
    <a mat-menu-item
      routerLink="/setting/buyers-groups" routerLinkActive="active"
      matTooltip="View and update the list of buyer grouping." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Buyer Grouping Page')">
      Buyer Grouping
    </a>
  </ng-template>
</mat-menu>

<mat-menu #integrationsMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item
      routerLink="/logistics/integrations/deringer" routerLinkActive="active"
      matTooltip="View deringer integration logs." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Deringer Page')">
      Deringer
    </a>
    <a mat-menu-item
      routerLink="/logistics/integrations/montship" routerLinkActive="active"
      matTooltip="View montship integration logs." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Montship Page')">
      Montship
    </a>
    <a mat-menu-item
      routerLink="/logistics/integrations/aes" routerLinkActive="active"
      matTooltip="View AES integration logs." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited AES Page')">
      AES
    </a>
    <a mat-menu-item
      routerLink="/logistics/integrations/macropoint" routerLinkActive="active"
      matTooltip="View Macropoint integration logs." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Macropoint Page')">
      Macropoint
    </a>
  </ng-template>
</mat-menu>

<mat-menu #logisticsMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item
      routerLink="/logistics/shipping-log" routerLinkActive="active"
      matTooltip="View your list of shipments associated with active deals." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Shipping Log Page')">
      Shipping Log
    </a>
    <a mat-menu-item
      routerLink="/logistics/indexing-report" routerLinkActive="active"
      matTooltip="View your list of corrupt deals" matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Indexing Report Page')">
      Indexing Report
    </a>
    <a mat-menu-item
      routerLink="/logistics/vendor-invoices" routerLinkActive="active"
      matTooltip="View vendor's invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Vendor Invoices Page')">
      Vendor Invoices
    </a>
    <a mat-menu-item
      routerLink="/logistics/buyer-invoices" routerLinkActive="active"
      matTooltip="View buyer's invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Buyer Invoices Page')">
      Buyer Invoices
    </a>
    @if (WA_11596_CREDIT_NOTES_UPGRADE) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.logistics.credit-notes-upgraded')" routerLinkActive="active"
        matTooltip="View credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Credit Notes Page')">
        Credit Notes
      </a>
    } @else {
      <a mat-menu-item
        [routerLink]="routeUrl('app.logistics.credit-notes')" routerLinkActive="active"
        matTooltip="View credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Credit Notes Page')">
        Credit Notes
      </a>
    }
    @if (WA_11595_VENDOR_CREDITS_UPGRADE) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.logistics.vendor-credits-upgraded')" routerLinkActive="active"
        matTooltip="View vendor credits." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Vendor Credits Page')">
        Vendor Credits
      </a>
    } @else {
      <a mat-menu-item
        [routerLink]="routeUrl('app.logistics.vendor-credits')" routerLinkActive="active"
        matTooltip="View vendor credits." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Vendor Credits Page')">
        Vendor Credits
      </a>
    }
    <a mat-menu-item
      routerLink="/logistics/integrations/deringer" routerLinkActive="active"
      [matMenuTriggerFor]="integrationsMenu"
      (click)="track('Visited Integrations Page')">
      Integrations
    </a>
    <a mat-menu-item
      [routerLink]="routeUrl('app.logistics.transportations')" routerLinkActive="active"
      matTooltip="View your list of shipping segments associated with active shipments." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Transportation Summary Page')">
      Transportation Summary
    </a>
    <a mat-menu-item
      [routerLink]="routeUrl('app.logistics.freight_rates')" routerLinkActive="active"
      matTooltip="View active freight rates available for shipping." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Freight Rates Page')">
      Freight Rates
    </a>
    <a mat-menu-item
      routerLink="/logistics/upload-history" routerLinkActive="active"
      matTooltip="View upload history." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Upload History Page')">
      Upload History
    </a>
    <a mat-menu-item
      [routerLink]="routeUrl('app.logistics.client_shipping_log')" routerLinkActive="active"
      matTooltip="Edit your client's shipping log view." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Client Shipping Log Page')">
      Client Shipping Log
    </a>
    <a mat-menu-item
      [routerLink]="routeUrl('app.logistics.documents')" routerLinkActive="active"
      matTooltip="View documents." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Documents Page')">
      Documents
    </a>
    <a mat-menu-item
      [routerLink]="routeUrl('app.logistics.ar-report')" routerLinkActive="active"
      matTooltip="This report displays information for overdue buyer invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited AR Report Page')">
      AR Report
    </a>
    <mat-divider></mat-divider>
    <a mat-menu-item
      routerLink="/setting/companies" routerLinkActive="active"
      matTooltip="View and update the list of companies, departments, and business types." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Company Settings Page')">
      Company Settings
    </a>
    <a mat-menu-item
      routerLink="/setting/products-services" routerLinkActive="active"
      matTooltip="View and update the list of products, product types, product categories, and product specifications." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Products & Services Page')">
      Products & Services
    </a>
    <a mat-menu-item
      routerLink="/setting/locations" routerLinkActive="active"
      matTooltip="View and update the list of locations available for shipping." matTooltipPosition="left" matTooltipClass="nav-tooltip"
      (click)="track('Visited Locations Page')">
      Locations
    </a>
  </ng-template>
</mat-menu>

<mat-menu #financialMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    @if (WA_11596_RECEIPTS_UPGRADE) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.financial.receipts-upgraded')" routerLinkActive="active"
        matTooltip="View and upload receipts received from clients." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Receipts Page')">
        Receipts
      </a>
    } @else {
      <a mat-menu-item
        [routerLink]="routeUrl('app.financial.receipts')" routerLinkActive="active"
        matTooltip="View and upload receipts received from clients." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Receipts Page')">
        Receipts
      </a>
    }
    <a mat-menu-item
      [routerLink]="routeUrl('app.financial.fx-rates')" routerLinkActive="active"
      matTooltip="View past and present foreign exchange rates." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited FX Rates Page')">
      FX Rates
    </a>
    <a mat-menu-item
      routerLink="/financial/payables" routerLinkActive="active"
      matTooltip="View client's payables." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Payables Page')">
      Payables
    </a>
    @if (WA_11595_VENDOR_CREDITS_UPGRADE) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.financial.vendor-credits-upgraded')" routerLinkActive="active"
        matTooltip="View your list of vendor credits." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Vendor Credits Page')">
        Vendor Credits
      </a>
    } @else {
      <a mat-menu-item
        [routerLink]="routeUrl('app.financial.vendor-credits')" routerLinkActive="active"
        matTooltip="View your list of vendor credits." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Vendor Credits Page')">
        Vendor Credits
      </a>
    }

    <a mat-menu-item
      routerLink="/financial/buyer-invoices" routerLinkActive="active"
      matTooltip="View buyer's payables." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Buyer Invoices Page')">
      Buyer Invoices
    </a>
    @if (WA_11596_CREDIT_NOTES_UPGRADE) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.financial.credit-notes-upgraded')" routerLinkActive="active"
        matTooltip="View credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Credit Notes Page')">
        Credit Notes
      </a>
    } @else {
      <a mat-menu-item
        [routerLink]="routeUrl('app.financial.credit-notes')" routerLinkActive="active"
        matTooltip="View credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Credit Notes Page')">
        Credit Notes
      </a>
    }
    <a mat-menu-item
      [routerLink]="routeUrl('app.financial.ar-report')" routerLinkActive="active"
      matTooltip="This report displays information for overdue buyer invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited AR Report Page')">
      AR Report
    </a>
  </ng-template>
</mat-menu>

<mat-menu #managementMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item
      [routerLink]="routeUrl('app.management.audit-trails')" routerLinkActive="active"
      matTooltip="View audit trails." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Audit Page')">
      Audit
    </a>
    @if (WA_11596_CREDIT_NOTES_UPGRADE) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.management.credit-notes-upgraded')" routerLinkActive="active"
        matTooltip="View credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Credit Notes Page')">
        Credit Notes
      </a>
    } @else {
      <a mat-menu-item
        [routerLink]="routeUrl('app.management.credit-notes')" routerLinkActive="active"
        matTooltip="View credit notes." matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Credit Notes Page')">
        Credit Notes
      </a>
    }
    <a mat-menu-item
      [routerLink]="routeUrl('app.management.credit-overrides')" routerLinkActive="active"
      matTooltip="View credit overrides." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Credit Overrides Page')">
      Credit Overrides
    </a>
    <a mat-menu-item
      routerLink="/management/vendor-invoices" routerLinkActive="active"
      matTooltip="View vendor's invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Vendor Invoices Page')">
      Vendor Invoices
    </a>
    <a mat-menu-item
      routerLink="/management/buyer-invoices" routerLinkActive="active"
      matTooltip="View buyer's invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Buyer Invoices Page')">
      Buyer Invoices
    </a>
    <a mat-menu-item
      [routerLink]="routeUrl('app.management.ar-report')" routerLinkActive="active"
      matTooltip="This report displays information for overdue buyer invoices." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited AR Report Page')">
      AR Report
    </a>
    <a mat-menu-item
      routerLink="/management/negative-deals" routerLinkActive="active"
      matTooltip="This shows negative deals." matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Negative Deals Page')">
      Negative Deals
    </a>
  </ng-template>
</mat-menu>

<mat-menu #hamburgerMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    @if (canAccess('app.trading')) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.trading')" routerLinkActive="active"
        [matMenuTriggerFor]="tradingMenu">
        <i class="fas fa-globe" aria-hidden="true"></i>
        Trading
        @if (newMatchedOffers) {
          <span class="fas fa-gift info-accent"></span>
        }
      </a>
    }
    @if (canAccess('app.logistics')) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.logistics')" routerLinkActive="active"
        [matMenuTriggerFor]="logisticsMenu">
        <i class="fas fa-truck" aria-hidden="true"></i>
        Logistics
      </a>
    }
    @if (canAccess('app.financial')) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.financial')" routerLinkActive="active"
        [matMenuTriggerFor]="financialMenu">
        <i class="fas fa-money-bill-1" aria-hidden="true"></i>
        Financial
      </a>
    }
    @if (canAccess('app.reports')) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.reports')" routerLinkActive="active"
        [matMenuTriggerFor]="chartMenu">
        <i class="fas fa-chart-bar" aria-hidden="true"></i>
        Reports
      </a>
    }
    @if (canAccess('app.management')) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.management')" routerLinkActive="active"
        [matMenuTriggerFor]="managementMenu">
        <i class="fas fa-briefcase" aria-hidden="true"></i>
        Management
      </a>
    }
    @if (canAccess('app.trading.future-bids') && enableMultipleBids) {
      <a mat-menu-item
        [routerLink]="routeUrl('app.trading.future-bids')" routerLinkActive="active"
        (click)="track('Visited Multiple Bids Page')">
        <i class="fas fa-hand-holding-dollar" aria-hidden="true"></i>
         My Bids
      </a>
    }
  </ng-template>
</mat-menu>

<mat-menu #chartMenu="matMenu" color="primary">
  <ng-template matMenuContent>
    <a mat-menu-item
      matTooltip="Reports" matTooltipPosition="right" matTooltipClass="nav-tooltip"
      (click)="track('Visited Tableau'); goToLink('https://tableau.tradecafe.com')">
      Reports
    </a>
    @if (enableChartFeature) {
      <a mat-menu-item
        routerLink="/reports/chart" routerLinkActive="active"
        matTooltip="Internal Pricing Chart" matTooltipPosition="right" matTooltipClass="nav-tooltip"
        (click)="track('Visited Internal Pricing Chart Page')">
        Chart
      </a>
    }
  </ng-template>
</mat-menu>
