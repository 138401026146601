import { keyBy, mapValues } from 'lodash-es'
import { ColumnDef } from 'src/services/table-utils'

export type PathsListColumn =
  | 'expand'
  | 'select'
  | 'provider'
  | 'origin'
  | 'destination'
  | 'portLoading'
  | 'portDischarge'
  | 'estAmt'
  | 'estAmtCad'
  | 'transitTime'
  | 'stops'
  | 'startDate'
  | 'endDate';

export const PATH_COLUMNS: ColumnDef<PathsListColumn>[] = [
  { field: 'expand',             displayName: '', internal: true, index: -1 },
  { field: 'select',             displayName: 'Select' },
  { field: 'provider',           displayName: 'Carrier' },
  { field: 'estAmt',             displayName: 'Est Amt' },
  { field: 'estAmtCad',          displayName: 'Est Amt (CAD)' },

  { field: 'origin',             displayName: 'Origin' },
  { field: 'destination',        displayName: 'Destination' },
  { field: 'portLoading',        displayName: 'Port Loading' },
  { field: 'portDischarge',      displayName: 'Port Discharge' },

  { field: 'stops',              displayName: 'No of Stops' },
  { field: 'transitTime',        displayName: 'No of Days' },
  { field: 'startDate',          displayName: 'Start Date' },
  { field: 'endDate',            displayName: 'Delivery Date' },
];

export const PATH_COLUMN_NAMES = mapValues(keyBy(PATH_COLUMNS, 'field'), 'displayName');

export const DEFAULT_COLUMNS: Record<string, PathsListColumn[]> = {
  ['default']: [
    'expand',
    'provider',
    'estAmt',
    'estAmtCad',
    'origin',
    'destination',
    'portLoading',
    'portDischarge',
    'transitTime',
    'stops',
    'startDate',
    'endDate',
    'select',
  ]
};
