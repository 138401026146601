import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Store, select } from '@ngrx/store'
import { Carrier, LocationObject, ShipmentRate } from '@tradecafe/types/core'
import { DeepReadonly } from '@tradecafe/types/utils'
import { clone, compact, get, isNil, uniq } from 'lodash-es'
import { ReplaySubject, combineLatest } from 'rxjs'
import { selectCarrierEntities } from 'src/app/store/carriers'
import { selectLocationEntities } from 'src/app/store/locations'
import { loadShipmentRatesByIds, selectShipmentRateEntities } from 'src/app/store/shipment-rates'
import { selectUnlocodeEntities } from 'src/app/store/unlocodes'
import { PathObject, RateNode, RouteNode, RoutesService, isRateNode } from 'src/services/data/routes.service'
import { UnlocodeEx } from 'src/services/data/unlocodes.service'
import { waitNotEmpty } from 'src/services/data/utils'
import { TableSelection } from 'src/services/table-utils/selection/table-selection'
import { DEFAULT_COLUMNS, PATH_COLUMN_NAMES } from './paths-list.columns'

const PATH_COUNT = 5;

// This is the definition of a PathRow (i.e. a row in the grid)
export interface PathRow {
  provider?: string;
  estAmt?: number;
  currency?: string;
  estAmtCad: number;
  transitTime: number;
  stops: number;
  startDate?: number;
  endDate?: number;

  origin?: string;
  destination?: string;
  firstRate?: DeepReadonly<ShipmentRate>,
  lastRate?: DeepReadonly<ShipmentRate>,
  portLoading?: string;
  portDischarge?: string;

  path: RouteNode[];
  route: PathNodeRow[];
  expanded: boolean;

  pathId: string;
}

export interface PathNodeRow {
  rateId: string;
  provider: string;
  type: string;
  estAmt: number;
  currency: string;
  estAmtCad: number,
  transitTime: number;
  startDate: number;
  endDate: number

  origin: string;
  destination: string;
  portLoading: string;
  portDischarge: string;
}

export type PathMetricOptions = 'cost' | 'time' | 'length';

@Component({
  selector: 'tc-paths-list',
  templateUrl: './paths-list.component.html',
  styleUrls: ['./paths-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PathsListComponent implements OnInit, OnChanges {
  constructor(
    private readonly cd: ChangeDetectorRef,
    private store: Store,
    private Routes: RoutesService,
  ) {}

  @HostBinding('class')
  class = 'tc-paths-list'

  // dynamic data
  dataSource = new MatTableDataSource<PathRow>(new Array(PATH_COUNT).fill(undefined));
  emptyData = new MatTableDataSource<PathRow>([undefined]);

  // settings
  @Input() readonly = true;
  @Input() displayColumns = DEFAULT_COLUMNS['default'];
  @Input() columnNames: Dictionary<string> = PATH_COLUMN_NAMES;
  @Input() pathMetric: PathMetricOptions = 'cost';
  @Input() startDate: number;
  @Input() originLocationId: string;
  @Input() originPort: string;
  @Input() originIsPort: boolean;
  @Input() destinationLocationId: string;
  @Input() destinationPort: string;
  @Input() destIsPort: boolean;
  @Input() isDisabled = false;
  @Input() selectedPath: DeepReadonly<string[]>;

  @Output() updateSelectedPath = new EventEmitter<DeepReadonly<string[]>>()

  // referenced data (effectively, this is data that is useful is elaborating on provided data)
  // i.e. The path will have a carrier ID, but the _account_ has the name associated with it that we want to show
  // Note: if there are values we want to ensure are loaded, we should dispatch (see below) requests to load said data
  private carriers$ = this.store.pipe(select(selectCarrierEntities), waitNotEmpty());
  private locations$ = this.store.pipe(select(selectLocationEntities), waitNotEmpty());
  private shipmentRates$ = this.store.pipe(select(selectShipmentRateEntities), waitNotEmpty());
  private unlocodes$ = this.store.pipe(select(selectUnlocodeEntities), waitNotEmpty());
  private paths$ = new ReplaySubject<PathObject[]>(1);

  private selectedPathId: string;
  checkIsNil = isNil

  private readonly getPathId = (path: PathObject | RouteNode[]) => {
    path = get(path, 'path', path) as RouteNode[]; // One way or another, it should be an array of RouteNodes now

    if (!path) {
      return null;
    }

    const rateNodes = path.filter(x => isRateNode(x));
    if (rateNodes.length === 0) { // If there are no rate nodea, it isn't really a path
      return null;
    }

    return rateNodes.map(x => x.rate_id).join('|');
  }

  @ViewChild(MatPaginator)
  set paginator(paginator: MatPaginator) { this.dataSource.paginator = paginator };

  // table settings
  selection = new TableSelection<PathRow>('pathId', false);

  ngOnInit() {
    // Set the path metric value to default (by cost) if not defined
    this.pathMetric = this.pathMetric ?? 'cost';

    // Trigger this handler whenever this.paths$ changes
    // (i.e. paths have been loaded into the observable from _somewhere_)
    this.paths$.subscribe((paths: PathObject[]) => {
      const rateIds: string[] = [];

      // For each path object, pull out all the rate IDs involved
      for (const path of paths) {
        rateIds.push(...path.path.filter((x: RouteNode) => isRateNode(x)).map((x: RateNode) => x.rate_id));
      }

      // Send a signal to the store to load all these rates from the BE
      // (when the values are returned, it will trigger the grid to reload)
      this.store.dispatch(loadShipmentRatesByIds({ rate_ids: uniq(rateIds) }));
    });

    // Whenever _any_ of these observables emits a new value, the grid data will be recalculated/refreshed
    combineLatest([this.paths$, this.carriers$, this.locations$, this.unlocodes$, this.shipmentRates$])
      .subscribe(([paths, carriers, locations, unlocodes, shipmentRates]) => {
        this.setGridData(paths, carriers, locations, unlocodes, shipmentRates)
      });
  }

  ngOnChanges(change: SimpleChanges) {
    // Any other change doesn't cause the paths to be re-fetched/calculated
    if (
      change.pathMetric ||
      change.originLocationId ||
      change.originPort ||
      change.originIsPort ||
      change.destinationLocationId ||
      change.destinationPort ||
      change.destIsPort ||
      change.startDate
    ) {
      this.reloadData()
    }
  }

  toggleRow(row: PathRow) {
    row.expanded = !row.expanded;
    this.cd.detectChanges();
  }

  selectPathRow(row: PathRow) {
    // if the path is _already_ selected, there's nothing to do (can't _un_select it)
    if (this.selection.isSelected(row)) {
      return;
    }

    // Toggle the row selection
    this.selection.toggleRow(row);

    // Output the new Path rate IDs
    const routeIDs = row.route.map(r => r.rateId);
    // Set the selected path ID (for local tracking)
    this.selectedPathId = row.pathId;

    // Tell the parent element what the newly selected path is
    this.updateSelectedPath.next(routeIDs);
  }

  protected getRowId(_i: number, path: PathRow) {
    return path?.pathId;
  }

  private getPaths(count: number = PATH_COUNT, validUntil: number = Math.floor(Date.now() / 1000)) {
    const pickup = this.originIsPort ? this.originPort : this.originLocationId;
    const delivery = this.destIsPort ? this.destinationPort : this.destinationLocationId;

    // If either pickup or delivery is null/undefined, we can't find any paths
    if (isNil(pickup) || isNil(delivery)) {
      return { paths: [] };
    }

    switch (this.pathMetric) {
      case 'cost':
        return this.Routes.getLowestCostPaths(pickup, delivery, count, { timestamp: validUntil });
      case 'length':
        return this.Routes.getShortestPaths(pickup, delivery, count, { timestamp: validUntil  });
      case 'time':
        return this.Routes.getFastestPaths(pickup, delivery, count, { timestamp: validUntil });
      default:
        throw new Error('Metric not supported: ' + this.pathMetric);
    }
  }

  private async reloadData() {
    // If this is disabled, we don't need to get paths (any previously selected paths are handled separately)
    if (this.isDisabled) {
      this.paths$.next([]);
    } else {
      // Otherwise, use current parameters to get the list of paths and put them in the observable
      const { paths } = await this.getPaths(5, this.startDate);
      this.paths$.next(paths);
    }
  }

  // INPUT: A Path/Route between an origin and a destination.
  // OUTPUT: An object which can be processed by the Material Grid for display/use (i.e. A PathRow)
  private buildPathRow(
    pathObj: PathObject,
    carriers: Dictionary<DeepReadonly<Carrier>>,
    locations: DeepReadonly<Dictionary<LocationObject>>,
    unlocodes: DeepReadonly<Dictionary<UnlocodeEx>>,
    shipmentRates: DeepReadonly<Dictionary<ShipmentRate>>,
  ): PathRow {
    const rates = pathObj.path.filter(isRateNode);
    if (rates.length === 0) { // If there isn't at least one rate, this isn't really a Path (and should probably be fixed from source)
      return undefined;
    }

    const firstRateNode = rates[0];
    const lastRateNode = rates[rates.length -1];

    let provider: string;
    if (rates.length === 1) { // If there's exactly 1 rate, then we want to show the provider for it
      provider = carriers[firstRateNode.carrier_id]?.name;
    }

    // Origin and Destination might not be a Location, they might be a Port (Unlocode)
    const origin = locations[firstRateNode.origin_id]?.name ?? unlocodes[firstRateNode.origin_id]?.displayName;
    const destination = locations[lastRateNode.destination_id]?.name ?? unlocodes[lastRateNode.destination_id]?.displayName;

    const pathId = this.getPathId(pathObj);
    const pathFirstRate = shipmentRates[firstRateNode.rate_id];
    const pathLastRate = shipmentRates[lastRateNode.rate_id];

    const route = this.buildSubRows(rates, this.startDate, carriers, locations, unlocodes, shipmentRates);
    const pathEstAmtInfo = route.reduce((acc, val) => {
      if (acc.currency === '') {
        // First record, so use it as the basis
        acc.currency = val.currency;
        acc.total_amount += val.estAmt;
        return acc;
      } else if (acc.currency === val.currency) {
        // Matching currency to the previous records, so we sum it up
        acc.total_amount += val.estAmt;
        return acc;
      } else {
        // Welp, currencies don't match, so we'll show nothing for the top level of this path
        return { currency: null, total_amount: null };
      }
    }, {
      currency: '',
      total_amount: 0,
    });

    const pathRow: PathRow = {
      pathId: pathId,
      provider: provider,
      estAmt: pathEstAmtInfo.total_amount,
      currency: pathEstAmtInfo.currency,
      estAmtCad: pathObj.total_cost,
      transitTime: pathObj.total_time,
      stops: rates.length - 1, // There's always 1 'stop' to exclude, the destination (we want the number of intermediary stops)
      startDate: this.startDate,
      endDate: this.startDate + (pathObj.total_time * 60 * 60 * 24),
      origin: origin,
      destination: destination,

      firstRate: pathFirstRate, // These are separate so we know that the rate exists, even if/when the port values are nil
      lastRate: pathLastRate,
      portLoading: pathFirstRate?.port_loading,
      portDischarge: pathLastRate?.port_discharge,

      route: route,
      expanded: false,

      // Keep a copy, just in case
      path: pathObj.path,
    }

    // If this path matches the stored selected path, then we toggle that row to be selected
    if (pathId === this.selectedPathId) {
      this.selection.toggleRow(pathRow)
    }

    return pathRow;
  }

  private buildSubRows(
    rates: RateNode[],
    initialStartDate: number,
    carriers: Dictionary<DeepReadonly<Carrier>>,
    locations: DeepReadonly<Dictionary<LocationObject>>,
    unlocodes: DeepReadonly<Dictionary<UnlocodeEx>>,
    shipmentRates: DeepReadonly<Dictionary<ShipmentRate>>
  ): PathNodeRow[] {
    let timestamp = initialStartDate;
    return rates.map((node) : PathNodeRow => {
      // NB: The origin/destination of a Rate may not be a Location, it might be a Port (Unlocode)

      const originName = locations[node.origin_id]?.name ?? unlocodes[node.origin_id]?.displayName;
      const destinationName = locations[node.destination_id]?.name ?? unlocodes[node.destination_id]?.displayName;
      const shipmentRate = shipmentRates[node.rate_id]; // Note: this might _not_ find a result
      const carrier = carriers[shipmentRate?.carrier_id];
      const nodeStartDate = clone(timestamp);
      timestamp += shipmentRate?.attributes?.transit_time * 60 * 60 * 24;
      const nodeEndDate = clone(timestamp);

      return {
        provider: carrier?.name,
        rateId: node.rate_id,
        estAmt: node.rate_amount,
        currency: node.rate_currency,
        estAmtCad: node.rate_amount_CAD,
        origin: originName,
        destination: destinationName,
        portLoading: shipmentRate?.port_loading,
        portDischarge: shipmentRate?.port_discharge,
        type: shipmentRate?.type,
        transitTime: shipmentRate?.attributes?.transit_time,
        startDate: nodeStartDate,
        endDate: nodeEndDate,
      };
    }) || [];
  };

  private pathFromRateIds(shipmentRates: DeepReadonly<Dictionary<ShipmentRate>>, rateIds: DeepReadonly<string[]>): PathObject {
    const rates = rateIds.map(rateId => {
      const shipmentRate: DeepReadonly<ShipmentRate> = shipmentRates[rateId];

      return {
        rate_id: rateId,
        carrier_id: shipmentRate?.carrier_id,
        rate_amount_CAD: shipmentRate?.rate.amount, // TODO: Convert to CAD
        type: shipmentRate?.type,
        until: shipmentRate?.until,
        commodity: shipmentRate?.commodity,
        container_size: shipmentRate?.container_size + '',
        origin_id: shipmentRate?.origin_is_port ? shipmentRate?.port_loading : shipmentRate?.origin_id,
        destination_id: shipmentRate?.dest_is_port ? shipmentRate?.port_discharge : shipmentRate?.destination_id,
        weight_max_lbs: shipmentRate?.weight?.max, // TODO: Conversion to lbs (depends on metric)
        weight_metric: shipmentRate?.weight?.metric || 'lbs', // r.weight.metric TODO: Figure out how to get the metric here
        weight_max: shipmentRate?.weight?.max,
        rate_amount: shipmentRate?.rate?.amount,
        rate_currency: shipmentRate?.rate?.currency,
        transit_time: shipmentRate?.attributes?.transit_time,
        labels: ['Rate'],
      };
    });

    const pathTotalCost = rates.reduce((acc, r) => acc + (r?.rate_amount_CAD ?? 0), 0);
    const pathTotalTime = rates.reduce((acc, r) => acc + (r?.transit_time ?? 0), 0);

    return {
      path: rates,
      length: rates.length,
      total_cost: pathTotalCost,
      total_time: pathTotalTime
    };
  }

  private setGridData(
    paths: PathObject[],
    carriers: Dictionary<DeepReadonly<Carrier>>,
    locations: DeepReadonly<Dictionary<LocationObject>>,
    unlocodes: DeepReadonly<Dictionary<UnlocodeEx>>,
    shipmentRates: DeepReadonly<Dictionary<ShipmentRate>>,
  ) {
    let rows = compact(
      paths?.map((path) => this.buildPathRow(path, carriers, locations, unlocodes, shipmentRates)),
    );

    // If the source has a selected path
    if (this.selectedPath?.length) {
      // Find it in the list of paths we know
      const selectedPathId = this.selectedPath.join('|');
      let selectedPathRow = rows.find(x => x.pathId === selectedPathId);

      if (!selectedPathRow) {
        // The path wasn't found in the returned rows, so we need to _generate_ it from the list of rate IDs instead
        const generatedPath = this.pathFromRateIds(shipmentRates, this.selectedPath);

        // Then build the PathRow from the pathObject (like we normally do)
        selectedPathRow = this.buildPathRow(generatedPath, carriers, locations, unlocodes, shipmentRates);

        // A selected path that isn't in the list of results should go on the front of the results
        rows = [selectedPathRow].concat(rows);

        // And we should always make sure we adhere to the max result count
        if (rows.length > PATH_COUNT) {
          rows = rows.slice(0, PATH_COUNT);
        }
      }

      // Make sure the row is marked as selected
      this.selection.toggleRow(selectedPathRow);
    }

    this.dataSource.data = rows;
  }
}
